const Turbolinks = require('turbolinks');

import { Archive } from './components/archive';
import { ContentMenu } from './components/content-menu';
import { Map } from './components/map';
import { Navigation } from './components/navigation';
import { ContentMore } from './components/content-more';

(function ($) {
    Turbolinks.start();

    ContentMenu.init();
    Archive.init();
    Map.init();
    Navigation.init();
    ContentMore.init();

    $('.carousel .wp-block-group__inner-container').slick({
        dots: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000
    });

    $(document).on('turbolinks:before-visit', function (event) {
        if (event.originalEvent.data.url == window.location.href) {
            event.preventDefault();
        }
    });

    $(document).on('turbolinks:request-start', function (event) {
        var xhr = event.originalEvent.data.xhr;
        xhr.setRequestHeader('X-Iv-Turbo', '1');
    });

    $(document).on('turbolinks:load', function (event) {
        if (window.google_tag_manager) {
            var url = event.originalEvent.data.url;
            dataLayer.push({
                event: 'pageView',
                virtualUrl: url,
            });
        }
        if (typeof ga == 'function') {
            ga('set', 'location', url);
            ga('send', 'pageview');
        }

        // Set Turbolinks.scrollPos if [data-turbolinks-noscroll] is clicked
        if (document.querySelectorAll('[data-turbolinks-noscroll]').length > 0) {
            document
                .querySelectorAll('[data-turbolinks-noscroll]')
                .forEach(function (element) {
                    element.addEventListener('click', () => {
                        Turbolinks.scrollPos = document.scrollingElement.scrollTop;
                    document.documentElement.classList.add('no-smooth-scroll');
                });
                });
        }

        // Don't scroll to top after visit if Turbolinks.scrollPos is set
        if (typeof Turbolinks.scrollPos == 'number') {
            document.scrollingElement.scrollTo(0, Turbolinks.scrollPos);

            // enable smooth scroll again after 500 ms
            setTimeout(function () {
                document.documentElement.classList.remove('no-smooth-scroll');
                Turbolinks.scrollPos = false;
            }, 500);
        }
    });

    $('a:not([data-turbolinks-noscroll])').each(function (){
        let target = $(this).attr('target');
        if ( !target ) {
            $(this).attr('target', '_self');
        }
    });


    if ( $( ".dwd-wp-banner-large" ).length) {
        $( ".dwd-wp-banner-large" ).wrap( "<div class='dwd-wp-banner-large-wrapper'></div>" );
    }

    $('.full-link').each(function (){
        let link = $(this).find('a').attr('href');
        $(this).append('<a class="full-link-inner" href="'+link+'"></a>');
    });

    if ( $( ".popup-button" ).length) {

        $( ".popup-button" ).each(function(){

            var scroll_pos = 0;
            $(this).click(function(e){
                let popupId = $(this).find('a').attr('href');

                if( $(popupId).length ) {
                    if ( $(popupId).hasClass('popup') ) {
                        $('html').addClass('is-popup-open');
                        scroll_pos = $(this).offset().top - 200;
                        $(popupId + '> div').prepend('<button class="close-button" type="button"><span aria-hidden="true">×</span></button>');
                        $(popupId).show();
                    }
                }
                return e.preventDefault();
            });

            $(document).on('click', '.popup', function(e){
                if( $(e.target).closest('.popup > div').length != 0 )
                    return false;

                $('html').removeClass('is-popup-open');
                $('.close-button').remove();
                $('.popup').hide();
                $(window).scrollTop(scroll_pos);
            });

            $(document).on('click', '.close-button', function(e){
                $('html').removeClass('is-popup-open');
                $('.close-button').remove();
                $('.popup').hide();
                $(window).scrollTop(scroll_pos);
            });

        });

    }

    $(document).on('click', '.navbar-toggle', function (e) {
        e.preventDefault();
        $('header').toggleClass('header-open');
    });

})(jQuery);



